import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap, debounce } from "rxjs/operators";
import { of as observableOf, Observable, BehaviorSubject } from "rxjs";

import { environment } from "../../../environments/environment";

import { Login } from "../../shared/models/login.model";
const credentialsKey = "currentUser";

@Injectable()
export class AuthenticationService {
  public loginDetailsBs = new BehaviorSubject(null);
  public obsLoginDetails = this.loginDetailsBs.asObservable();

  public loggedOutBs = new BehaviorSubject(false);
  public obsLoggedOutBs = this.loggedOutBs.asObservable();

  constructor(private http: HttpClient) {}

  login(loginData): Observable<any> {
    const href = `${environment.login}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
        }
        return data;
      })
    );
  }

  logout(): Observable<boolean> {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    sessionStorage.removeItem("csrfToken");
    return observableOf(true);
  }

  getUserInfo(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  isLogin() {
    if (localStorage.getItem(credentialsKey)) {
      return true;
    }
    return false;
  }

  getToken() {
    const savedCredentials = this.getUser();
    return savedCredentials && savedCredentials["token"];
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials["role"]);
  }

  getUserDepartment(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials["department"]);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      return savedCredentials["role"];
    } else {
      return false;
    }
  }

  getUser() {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    return JSON.parse(savedCredentials);
  }

  setUser(user: any): void {
    localStorage.setItem(credentialsKey, JSON.stringify(user));
  }

  setLoginDetails(loginDetails: any): void {
    localStorage.setItem("loginDetails", JSON.stringify(loginDetails));
  }
  getLoginDetails(): void {
    const loginDetail = localStorage.getItem("loginDetails") || null;
    return JSON.parse(loginDetail);
  }

  sendPasswordLink(data): Observable<any> {
    const href = environment.sendPasswordLink;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }

  getOtp(data): Observable<any> {
    const href = environment.getOtp;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }

  regestrationOtp(data): Observable<any> {
    const href = environment.regestrationOtp;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }
  verifyOtpRegistration(data): Observable<any> {
    const href = environment.verifyOtpRegistration;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }

  // registartionFrom(data): Observable<any> {
  //   const href = environment.registartionFrom;
  //   return this.http.post<any>(href, data).pipe(map((res: any) => {
  //     if (res.status == 'success') {
  //       return res.response;
  //     }
  //   }));
  // }

  registartion(loginData): Observable<any> {
    const href = `${environment.registartion}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
        }
        return data.response;
      })
    );
  }

  // verifyOtp(data): Observable<any> {
  //   const href = environment.verifyOtp;
  //   return this.http.post<any>(href, data).pipe(map((res: any) => {
  //     if (res.status == 'success') {
  //       return res;
  //     }
  //   }));
  // }

  verifyOtp(loginData): Observable<any> {
    const href = `${environment.verifyOtp}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
        }
        return data;
      })
    );
  }

  resetPassword(formData): Observable<any> {
    const href = environment.resetPassword;
    return this.http.post<any>(href, formData).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  adminLogin(loginData: Login): Observable<any> {
    const href = `${environment.adminLogin}`;
    return this.http.post<any>(href, loginData).pipe(
      tap((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          this.setUser(data.response);
        }
        return data;
      })
    );
  }

  createStaffPassword(payload): Observable<any> {
    const href = environment["createStaffPassword"];
    return this.http.post<any>(href, payload).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  sendPasswordLinkStaff(data): Observable<any> {
    const href = environment.sendPasswordLinkStaff;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }

  sendResetPasswordLinkStaff(data): Observable<any> {
    const href = environment.sendResetPasswordLinkStaff;
    return this.http.post<any>(href, data).pipe(
      map((res: any) => {
        if (res.status == "success") {
          return res.response;
        }
      })
    );
  }

  resetStaffPassword(payload): Observable<any> {
    const href = environment.resetStaffPassword;
    return this.http.post<any>(href, payload).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getCSRFToken(): Observable<any> {
    const href = environment["getCSRFToken"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // console.log("csrf token ===>",data)
          return data.response;
        }
      })
    );
  }
  emailLinkVerification(data) {
    const href = environment.emailLinkVerification;
    return this.http.get<any>(href, { params: data }).pipe(
      map((data: any) => {
        // if (data.status == 'success') {
        return data;
        // }
      })
    );
  }

  emailLinkVerificationadmin(data) {
    const href = environment.emailLinkVerificationadmin;
    return this.http.get<any>(href, { params: data }).pipe(
      map((data: any) => {
        // if (data.status == 'success') {
        return data;
        // }
      })
    );
  }
}
