import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UsersService } from "../../services";
const credentialsKey = "currentUser";
@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  downloadFile: string;

  tabChange = false;

  applicationData = new BehaviorSubject(null);
  obsApplicationData = this.applicationData.asObservable();

  installerFormOnce = false;
  permitType = null;

  saveAndExit = false;

  whatFormData: any;
  burglarInstallerFormData: any;
  fireInstallerFormData: any;

  constructor(private http: HttpClient, private userService: UsersService) {}

  callApplicationAPI(payload): Observable<any> {
    const href = `${environment.application}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  resubmitApplication(payload): Observable<any> {
    const href = `${environment.applicationResubmit}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  removeOwnerOfficer(payload) {
    const href = `${environment.deleteOwnerOfficer}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  editOwnerOfficer(payload) {
    const href = `${environment.editOwnerOfficer}`;
    return this.http.post<any>(href, payload).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  savePayment(data): Observable<any> {
    const href = `${environment.applicationPayment}`;
    return this.http.post<any>(href, data).pipe(
      tap((data) => {
        if (data.status === "success") {
        }
        return data;
      })
    );
  }

  getApplicationFee(query): Observable<any> {
    const href = `${environment.getApplicationFee}`;
    return this.http.get<any>(href, { params: query }).pipe(
      tap((data) => {
        if (data.status === "success") {
          return data;
        }
        return data;
      })
    );
  }

  searchAddress(searchQuery): Observable<any> {
    const href = environment["searchAddress"];
    return this.http.get<any>(href, { params: searchQuery }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  setTabChange(val) {
    this.tabChange = val;
  }

  getTabChange() {
    return this.tabChange;
  }

  setInstallerFormRouteOnce(val) {
    this.installerFormOnce = val;
  }

  getInstallerFormRouteOnce() {
    return this.installerFormOnce;
  }

  setPermitType(val) {
    this.permitType = val;
  }

  getPermitType() {
    return this.permitType;
  }

  downloadPermit(id, downloadType, exe): void {
    let params = new HttpParams();
    params = params.set("downloadType", downloadType);
    params = params.set("exe", exe);
    this.downloadFile =
      `${environment.host}` +
      `${environment.downloadPermit}` +
      `${id}` +
      `${"?"}` +
      params.toString();
    window.open(this.downloadFile, "_blank");
  }

  newDownloadPermit(id: any, downloadType: any, exe: any): void {
    let params = new HttpParams();
    params = params.set("downloadType", downloadType);
    params = params.set("exe", exe);
    this.downloadFile =
      `${environment.host}` + `${environment.newDownloadPermit}/${id}`;

    window.open(this.downloadFile, "_blank");
  }

  setSaveAndExit(val) {
    this.saveAndExit = val;
  }

  getSaveAndExit() {
    return this.saveAndExit;
  }

  setWhatFormData(obj) {
    this.whatFormData = obj;
  }

  getWhatFormData() {
    return this.whatFormData;
  }

  setBurglarInstallerForm(obj) {
    this.burglarInstallerFormData = obj;
  }

  getBurglarInstallerForm() {
    return this.burglarInstallerFormData;
  }

  setFireInstallerForm(obj) {
    this.fireInstallerFormData = obj;
  }

  getFireInstallerForm() {
    return this.fireInstallerFormData;
  }

  saveAdditionalInfo(payload) {
    const href = `${environment.applicationAdditionalInfo}`;
    return this.http.post<any>(href, payload).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  uploadAdditionalInfoDoc(payload) {
    const href = `${environment.applicationAdditionalInfoDocUpload}`;
    return this.http.post<any>(href, payload).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  deleteAdditionalInfoDoc(payload) {
    const href = `${environment.additionalInfoDocDelete}`;
    return this.http.post<any>(href, payload).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  getAdditionalInfoDoc(id) {
    const href = `${environment.getApplicationAdditionalInfo}/${id}`;
    return this.http.get<any>(href).pipe(
      map((data) => {
        if (data.status === "success") {
          return data.response;
        }
      })
    );
  }

  verifyCSRFToken(tokenQuery) {
    const href = `${environment.verifyCSRFToken}`;
    return this.http.get<any>(href, { params: tokenQuery }).pipe(
      map((data) => {
        if (data.status === "success") {
          const storage = localStorage;
          storage.setItem(credentialsKey, JSON.stringify(data.response));
          this.userService.userDetails.next(data.response);
          return data.response;
        }
      })
    );
  }
}
